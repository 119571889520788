
.app{
  margin: 0;
  min-height: 100vh;
  position: relative;
  padding-bottom: 250px;/*←footerの高さ*/
  box-sizing: border-box;/*←全て含めてmin-height:100vhに*/
}

.main{
  /* margin: auto 15px auto 15px; */
}
.page{
    /* margin: auto 15px auto 15px; */
    margin: auto;
    margin-bottom: 80px;
    width: 90%;
    max-width: 800px;
}
.page > h1, h2, p{
  /* margin-bottom: 0; */
}






.MuiBox-root{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-exit-container{
  width: 20px;
  height: 20px;
  position: absolute;
  top: 16px;
  right: 13px;
}
.modal-exit-icon{
  font-size: 20px;
  cursor: pointer;
  filter: grayscale(50%);
}
.modal-logo{
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.modal-logo-icon{
  width: 15px;
  height: 15px;
  background-color: var(--boken-green);
  border-radius: 100%;
}
.modal-logo-text{
  font-size: 27px;
  font-weight: 600;
  margin-left: 2px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.modal-text{
  margin: 0;
  margin-top: 13px;
  /* font-family: monospace; */
  font-size: 16px;
  font-weight: 400;
  /* font-style: italic; */
}

.modal-link{
  margin-top: 7px;
  font-size: 15px;
  font-weight: 300;
}
body[data-theme="light"] .modal-link{
  color: var(--light-text)
}
body[data-theme="dark"] .modal-link{
  color: var(--dark-text)
}

.google-login-button-container{
  display: flex;
  justify-content: center;
  margin-top: 27px;
}



/* 以下Google LoginボタンCSS */
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  /* background-color: WHITE; */
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* color: #1f1f1f; */
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  /* min-width: min-content; */
  min-width: 210px
}
body[data-theme="light"] .gsi-material-button{
  background-color: var(--light-gray);
  color: black;
}
body[data-theme="dark"] .gsi-material-button{
  background-color: var(--dark-gray);
  color: white;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
