


.notice-nav{
    display: flex;
}
.notice-nav-tab{
    text-decoration: none;
    padding: 10px 8px;
    border-bottom: 1px solid gray;
    cursor: pointer;
    transition: border-color 0.2s, color 0.2s;
}
body[data-theme="light"] .notice-nav-tab{
    color: rgb(155, 155, 155);
}
body[data-theme="dark"] .notice-nav-tab{
    color: rgb(143, 143, 143);
}


body[data-theme="light"] .notice-nav-tab.notice-nav-tab-active {
    border-bottom: 1px solid black;
    color: black;
}
body[data-theme="dark"] .notice-nav-tab.notice-nav-tab-active {
    border-bottom: 1px solid white;
    filter: brightness(2.5);
}
/* .notice-nav-tab.notice-nav-tab-active{
    filter: brightness(2.5);
} */

/* .notice-nav-tab.notice-nav-tab-active {
    border-bottom: 1px solid rgb(46, 46, 46);
} */


.notice-nav-tab-spacer{
    width: 15px;
    border-bottom: 1px solid gray;
}