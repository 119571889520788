



.error-container{
    max-width: 350px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
}
.error-container > h1{
    font-size: 31px;
    margin-top: 12px;
}
.error-image{
    margin: auto;
    width: 100%;
    border-radius: 6px;
}
.error-return-button{
    cursor: pointer;
    width: 80px;
    height: 35px;
    border-radius: 7px;
    margin: auto;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}
body[data-theme="light"] .error-return-button{
    color: rgb(0, 0, 0);
    background-color: rgb(229, 229, 229);
}
body[data-theme="light"] .error-return-button:hover{
    background-color: rgb(235, 235, 235);
}
body[data-theme="dark"] .error-return-button{
    color: white;
    background-color: var(--dark-gray);
}
body[data-theme="dark"] .error-return-button:hover{
    /* background-color: rgb(56, 56, 56); */
    filter: brightness(1.2);
}



@media (max-width: 500px) {
    .error-container{
        width: 90%;
        max-width: 280px;
    }
    .error-image{
        max-width: 280px;
    }
    .error-container > h1{
        font-size: 25px;
    }
}