
body.edit-page > #root > .app {
    padding-bottom: 0;
}
body.edit-page > #root > .app > .main {
    /* height: 100vh; */
}

.edit-page{
    display: flex;
    width: 100%;
    /* height: calc(100vh - 290px); */
    height: 100vh;
    min-height: 300px;
}




.edit-sidebar{
    width: 270px;
    height: 100%;
    /* overflow-y: scroll; */
    /* overflow-y: hidden; */
}
body[data-theme="light"] .edit-sidebar{
    background-color: var(--light-gray);
}
body[data-theme="dark"] .edit-sidebar{
    background-color: var(--dark-back);
    /* background-color: var(--dark-gray); */
}
.edit-sidebar-handle{
    width: 228px;
    height: 29px;
    border-radius: 5px;
    margin: auto;
    /* margin-left: 20px; */
    margin-top: 2px;
    /* padding-left: 10%; */
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: gray;
}
body[data-theme="light"] .edit-sidebar-handle:hover{
    background-color: rgba(215, 214, 214, 0.378);
    /* #F1F6F9 */
}
body[data-theme="dark"] .edit-sidebar-handle:hover{
    background-color: rgba(60, 60, 60, 0.378);
    /* #F1F6F9 */
}
.sidebar-back-arrow{
    font-size: 12px;
    margin-top: 2px;
    margin-left: 5px;
}
.sidebar-handle-text{
    margin-left: 5px;
}
body[data-theme="light"] .nav-left-handle{
    color: rgb(61, 61, 61);
}
body[data-theme="dark"] .nav-left-handle{
    color: rgb(194, 194, 194);
}



.edit-sidebar-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin: auto;
    margin-top: 20px;
}

.search-input{
    width: 140px;
    height: 26px;
    border-radius: 6px;
    /* border: none; */
    /* padding-left: 10px; */
    font-size: 15px;
    display: block;
    padding-left: 5px;
}
.search-input:focus{
    outline: none;
}
body[data-theme="light"] .search-input{
    border: 0.5px solid rgb(86, 86, 86);
    background-color: var(--light-back);
}
body[data-theme="dark"] .search-input{
    border: 0.5px solid rgb(69, 69, 69);
    background-color: var(--dark-gray);
    color: white;
}
.sidebar-sort{
    position: relative;
}
.sort-icon-container{}
.sort-icon{
    width: 18px;
    height: 18px;
    cursor: pointer;
}
body[data-theme="light"] .sort-icon{
    color: rgb(36, 36, 36);
}
body[data-theme="dark"] .sort-icon{
    color: rgb(28, 28, 28);
}
.sort-menu{
    position: absolute;
    width: 140px;
    top: 21px;
    left: -10px;
    border-radius: 10px;
    z-index: 1000;
    padding: 5px 5px 5px 5px;
    overflow: hidden;
}
body[data-theme="light"] .sort-menu{
    /* background-color: rgb(255, 255, 255); */
    background-color: rgb(253, 254, 254);
    border: 1px solid #d6d6d6;
}
body[data-theme="dark"] .sort-menu{
    /* background-color: rgb(30, 30, 30);
    border: 0.5px solid rgb(88, 88, 88); */
    background-color: var(--dark-gray);
    border: 1px solid rgb(62, 62, 62);
}
.sort-option{
    font-size: 14px;
    font-weight: 400;
    height: 25px;
    padding: 3px;
    padding-left: 7px;
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-items: center;
    border-radius: 5px;
}
body[data-theme="light"] .sort-option:hover{
    background-color: rgb(236, 236, 236);
}
body[data-theme="dark"] .sort-option:hover{
    background-color: rgb(82, 82, 82);
}

.edit-sidebar-button{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
body[data-theme="light"] .edit-sidebar-button{
    /* background-color: rgb(231, 231, 231); */
    border: 0.5px solid rgb(180, 180, 180);
    background-color: black;
    color: rgb(247, 247, 247);
}
body[data-theme="dark"] .edit-sidebar-button{
    /* background-color: var(--dark-gray); */
    border: 0.5px solid rgb(180, 180, 180);
    background-color: var(--light-gray);
    color: rgb(23, 23, 23);

}
.edit-sidebar-icon{
    width: 14px;
}
.check-container{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
body[data-theme="light"] .check-container{
    background-color: rgb(231, 231, 231);
    border: 0.5px solid rgb(180, 180, 180);
}
body[data-theme="dark"] .check-container{
    border: 0.5px solid rgb(59, 59, 59);
    background-color: var(--dark-gray);
}
.check-icon{
    /* color: var(--boken-green); */
}




.country-table{
    height: calc(100vh - 149px);
    min-height: 411px;
    overflow-y: auto;
    margin-top: 10px;
    scrollbar-width: thin;
    /* scrollbar-color: rgba(0, 0, 0, 0.5) transparent;  */
    display: flex;
    flex-direction: column;
}
body[data-theme="light"] .country-table{
    scrollbar-color: rgb(207, 207, 207) transparent; 
}
body[data-theme="dark"] .country-table{
    scrollbar-color: rgb(82, 82, 82) transparent; 
}
/* スクロールバー全体のスタイル */
.country-table::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
  
/* スクロールバーのつまむ部分（スライダー）のスタイル */
.country-table::-webkit-scrollbar-thumb {
    /* background-color: rgba(0, 0, 0, 0.5); */
    border-radius: 10px;
}
body[data-theme="light"] .country-table::-webkit-scrollbar-thumb {
    background-color: rgb(207, 207, 207);
}
body[data-theme="dark"] .country-table::-webkit-scrollbar-thumb {
    background-color: rgb(82, 82, 82);
}
  
  /* スクロールバーのトラック部分（背景）のスタイル */
::-webkit-scrollbar-track {
    background: transparent;
}
.country-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 4px auto 4px auto
}
.country-cell-left{
    display: flex;
    justify-content: left;
    align-items: center;
    height: 28px;
}
.country-cell-cat{
    width: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
}
.css-tj5bde-Svg{
    width: 14px;
}
/* .cat-icon{
    width: 15px;
    height: 15px;
    background-color: var(--color-lived);
    border-radius: 200px;
} */
.cat-icon-lived{
    width: 15px;
    height: 15px;
    background-color: var(--color-lived);
    border-radius: 200px;
    margin-left: 18.5px;
}
.cat-icon-visited{
    width: 15px;
    height: 15px;
    background-color: var(--color-visited);
    border-radius: 200px;
    margin-left: 18.5px;
}
.cat-icon-transited{
    width: 15px;
    height: 15px;
    background-color: var(--color-transited);
    border-radius: 200px;
    margin-left: 18.5px;
}
.country-cell-name{
    margin-left: 5px;
}
.country-cell-func{
    /* width: 42px; */
    width: 20px;
    display: flex;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center;
}
.country-each-edit{
    width: 17px;
    /* margin-right: 5px; */
}
.country-each-edit-icon{
    width: 13px;
    cursor: pointer;
}
body[data-theme="light"] .country-each-edit-icon{
    color: rgb(61, 61, 61);
}
body[data-theme="dark"] .country-each-edit-icon{
    color: rgb(199, 199, 199);
}
.country-each-delete{
    width: 17px;
}
.country-each-delete-icon{
    width: 12px;
    cursor: pointer;
    /* color: rgb(155, 0, 0); */
    /* color: rgb(61, 61, 61); */
}
body[data-theme="light"] .country-each-delete-icon{
    color: rgb(61, 61, 61);
}
body[data-theme="dark"] .country-each-delete-icon{
    color: rgb(199, 199, 199);
}   



.no-country-text-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.no-country-text{
    font-size: 16px;
    font-weight: 400;
}










.edit-content{
    flex: 1;
    height: calc(100vh - 60px);
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 500px;
}
body[data-theme="light"] .edit-content{
    background-color: var(--light-back);
}
body[data-theme="dark"] .edit-content{
    background-color: var(--dark-gray);
    /* background-color: var(--dark-back); */
}
.edit-header{
    position: absolute;
    top: 5px;
    left: 20px;
    display: flex;
    align-items: flex-end;
}
.edit-header > h2{
    margin-bottom: 0;
}
body[data-theme="light"] .edit-header > h2{
    color: rgb(61, 61, 61);
}
body[data-theme="dark"] .edit-header > h2{
    color: rgb(194, 194, 194);
}
.edit-header > h2 > span{
    font-size: 15px;
    font-weight: 400;
    margin-left: 6px;
    cursor: pointer;
}
.edit-header > h2 > span:hover{
    filter: brightness(1.2);
}
.edit-other{
    /* margin-top: 3px; */
    margin-left: 10px;
    margin-bottom: 3px;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
}
.edit-other:hover{
    filter: brightness(1.2);
}
body[data-theme="light"] .edit-other{
    color: rgb(61, 61, 61);
}
body[data-theme="dark"] .edit-other{
    color: rgb(194, 194, 194);
}


.add-row-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.add-row-container > h3{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}
.edit-info-text{
    margin: 0;
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
}
.add-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 360px;
    height: 40px;
    /* margin: auto; */
    margin-top: 10px;
}
.cat-select{
    margin-left: 10px;
}
.country-add-button{
    width: 60px;
    height: 30px;
    border-radius: 5px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
}
body[data-theme="light"] .country-add-button{
    /* background-color: var(--boken-green); */
    background-color: black;
    color: white;
}
body[data-theme="dark"] .country-add-button{
    background-color: var(--light-gray);
    color: black;
}


.edit-page .map-container{
    width: 90%;
    max-width: 800px;
    min-height: 200px;
    /* height: 300px; */
    /* background-color: var(--dark-gray); */
    /* margin: auto; */
    margin-top: 20px;
    /* margin-bottom: 150px; */
    margin-bottom: 0;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 13/6 !important;
    position: relative;
}
body[data-theme="light"] .edit-page .map-container{
    background-color: #C2C8CA;
    /* border: 1px solid rgb(228, 228, 228); */
}
body[data-theme="dark"] .edit-page .map-container{
    background-color: var(--dark-gray);
    /* border: 1px solid var(--dark-back); */
    box-shadow: rgba(0, 0, 0, 0.46) 0px 1px 4px;
}
.edit-page .amcharts-container{
    width: 100%;
    height: 100%;
}
.edit-page.amcharts-map{
    width: 100%;
    /* max-width: 800px; */
    height: 100% !important;
    /* height: 300px; */
    /* border-radius: 10px; */
    aspect-ratio: 13/6;
}


.edit-data-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 120px;
}
.edit-data-each{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 110px;
}
.edit-data-top{
    font-size: 26px;
    font-weight: 400;
}
.edit-data-bottom{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.edit-data-icon-lived{
    width: 11px;
    height: 11px;
    background-color: var(--color-lived);
    border-radius: 300px;
    margin-bottom: 3px;
}
.edit-data-icon-visited{
    width: 11px;
    height: 11px;
    background-color: var(--color-visited);
    border-radius: 300px;
    margin-bottom: 3px;
}
.edit-data-icon-transited{
    width: 11px;
    height: 11px;
    background-color: var(--color-transited);
    border-radius: 300px;
    margin-bottom: 3px;
}
.edit-data-text{
    margin-left: 3px;
    font-size: 15px;
    font-weight: 400;
}








@media (max-width: 700px) {

    .edit-page{
        flex-direction: column-reverse;
        width: 100%;
        height: 100%;
    }


    .edit-content{
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        width: 100%;
        /* height: 800px; */
        height: auto;
        min-height: 0;
        padding-top: 0;
    }
    .edit-header{
        position: relative;
        width: 100%;
        top: 0 ;
        left: 0;
        margin-top: 5px;
    }
    .edit-header > h2{
        margin-left: 20px;
    }






    .edit-data-container{
        margin-top: 25px;
        margin-bottom: 0px;
    }
    .edit-data-each{
        width: 80px;
    }
    .edit-data-top{
        font-size: 21px;
        font-weight: 400;
    }
    .edit-data-icon-lived{
        width: 8px;
        height: 8px;
    }
    .edit-data-icon-visited{
        width: 8px;
        height: 8px;
    }
    .edit-data-icon-transited{
        width: 8px;
        height: 8px;
    }
    .edit-data-text{
        margin-left: 2px;
        font-size: 12px;
        font-weight: 400;
    }



    .edit-page .map-container{
        margin: 10px auto 0px auto;
        min-height: 170px;
    }
    .edit-page .amcharts-container{
        width: 100%;
        height: 100%;
        min-height: 170px;
    }
    .edit-page .amcharts-map{
        width: 100%;
        height: 100% !important;
        aspect-ratio: auto !important;
    }
    .edit-page .map-container > .city-map-container{
        height: 100%;
        min-height: 170px;
        aspect-ratio: auto;
    }
    .edit-page .city-map{
        width: 100%;
        height: 100%;
    }
    

    .add-row-container{
        margin-top: 10px;
        width: 100%;
    }
    .add-row-container > h3{
        font-size: 16px;
        font-weight: 500;
        width: 90%;
        text-align: center;
    }
    .edit-info-text{
        width: 95%;
        margin: auto;
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 8px;
    }
    .add-row{
        margin-top: 10px;
        margin-bottom: 45px ;
        /* transform: scale(0.9); */
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
        width: 98%;
    }
    .cat-select{
        margin-left: 5px;
    }



    .edit-sidebar{
        width: 100%;
        height: auto;
        margin-top: 10px;
    }
    .edit-page .nav-left{
        display: none;
    }
    .edit-sidebar-handle{
        display: none;
    }
    .edit-sidebar-row{
        width: 75%;
        justify-content: space-between;
    }
    .search-input{
        width: calc(100% - 100px);
    }
    .sort-menu{
        position: absolute;
        width: 140px;
        top: 21px;
        left: -120px;
        border-radius: 10px;
        z-index: 1000;
        padding: 5px 5px 5px 5px;
        overflow: hidden;
    }

    .country-table{
        /* height: calc(100vh - 118px); */
        height: 100%;
        overflow-y: none;
        overflow: hidden;
        margin-top: 10px;
    }
    .country-row{
        width: 85%;
    }

    .country-cell-left{
        display: flex;
        justify-content: left;
        align-items: center;
        height: 30px;
    }
    .country-cell-cat{
        width: 40px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border: none;
    }
    .css-tj5bde-Svg{
        width: 14px;
    }
    /* .cat-icon{
        width: 15px;
        height: 15px;
        background-color: var(--color-lived);
        border-radius: 200px;
    } */
    .cat-icon-lived{
        width: 15px;
        height: 15px;
        background-color: var(--color-lived);
        border-radius: 200px;
        margin-left: 18.5px;
    }
    .cat-icon-visited{
        width: 15px;
        height: 15px;
        background-color: var(--color-visited);
        border-radius: 200px;
        margin-left: 18.5px;
    }
    .cat-icon-transited{
        width: 15px;
        height: 15px;
        background-color: var(--color-transited);
        border-radius: 200px;
        margin-left: 18.5px;
    }
    .country-cell-name{
        margin-left: 5px;
        font-size: 17px;
    }
    .country-cell-func{
        /* width: 42px; */
        width: 20px;
        display: flex;
        /* justify-content: space-between; */
        justify-content: center;
        align-items: center;
    }
    .country-each-delete{
        width: 17px;
    }
    .country-each-delete-icon{
        width: 14px;
        cursor: pointer;
        /* color: rgb(155, 0, 0); */
        /* color: rgb(61, 61, 61); */
    }
}