.landing-page {
    margin-bottom: 100px;
}

.landing-top-bar {
    display: flex;
    justify-content: space-around;
    max-width: 1000px;
    margin: auto;
    margin-top: 50px;
}

.landing-left {
    width: 45%;
}

.landing-left-h1 {
    margin: auto;
    /* margin-top: 50px; */
    /* font-family: Arial, Helvetica, sans-serif; */
    /* font-family: Helvetica, sans-serif; */
    font-family: monospace;
    /* font-size: 48px; */
    /* font-weight: 500; */
    font-size: 53px;
    font-weight: 500;
}
/* Chrome */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    .landing-left-h1 {
        font-family: Arial, Helvetica, sans-serif;
    }
}
/* Safari */
::-webkit-full-page-media, :future, :root
.landing-left-h1 {
    font-family: monospace;
}
/** Mozilla Firefox */
@-moz-document url-prefix() {
    .landing-left-h1 {
        font-family: monospace;
    }
}
  

.landing-left-h1>span {
    color: var(--boken-green);
    line-height: 20px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 70px;
    margin-left: 5px;
}

.landing-left-p {
    margin: auto;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-family: monospace;
    font-size: 16px;
}
body[data-theme="light"] .landing-left-p{
    color: rgb(68, 68, 68);
}
body[data-theme="dark"] .landing-left-p{
    color: rgb(177, 177, 177);
}

.call-to-action-row {
    display: flex;
    justify-content: left;
    align-items: center;
    /* width: 300px; */
    width: 100%;
    margin: auto;
    margin-top: 20px;
}

.call-to-create {
    width: 130px;
    height: 45px;
    background-color: var(--boken-green);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    cursor: pointer;
}

.call-to-create:hover {
    filter: brightness(1.2);
}

.call-to-example {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    cursor: pointer;
    margin-left: 25px;
}



.landing-right {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.landing-type-row{
    display: flex;
    justify-content: center;
    width: 100%;
}
.landing-right-image-type{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    /* width: 60px; */
    /* height: 22px; */
    /* border-radius: 5px 5px 0px 0px; */
    /* margin-left: 5px; */
    padding-bottom: 3px;
    font-family: monospace;
    font-style: italic;
}
body[data-theme="light"] .landing-right-image-type{
    /* background-color: var(--light-gray); */
    color: rgb(68, 68, 68);
}
body[data-theme="dark"] .landing-right-image-type{
    /* background-color: var(--dark-gray); */
    color: rgb(179, 179, 179);
}
.right-image-container{
}
.right-image{
    width: 100%;

    border-radius: 6px;
}
body[data-theme="light"] .right-image{
    filter: brightness(1.1);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
body[data-theme="dark"] .right-image{
}


.landing-data-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}
.landing-data-each{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 110px;
}
.landing-data-top{
    font-size: 24px;
    font-weight: 400;
}
.landing-data-bottom{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.landing-data-icon-lived{
    width: 11px;
    height: 11px;
    background-color: var(--color-lived-green);
    border-radius: 300px;
    margin-bottom: 3px;
}
.landing-data-icon-visited{
    width: 11px;
    height: 11px;
    background-color: var(--color-visited-green);
    border-radius: 300px;
    margin-bottom: 3px;
}
.landing-data-icon-transited{
    width: 11px;
    height: 11px;
    background-color: var(--color-transited-green);
    border-radius: 300px;
    margin-bottom: 3px;
}
.landing-data-text{
    margin-left: 3px;
    font-size: 15px;
    font-weight: 400;
}









.embla {
    overflow: hidden;
    width: 100%;
    /* margin: auto; */
    border-radius: 5px;
    /* aspect-ratio: 13/6; */
}
  
.embla__container {
    display: flex;
}
  
.embla__slide {
    flex: 0 0 100%;
    text-align: center;
}
  
.carousel-image {
    max-width: 100%;
    /* height: 100%; */
    cursor: pointer;
    border-radius: 5px;
}
.carousel-city-light{
    filter: brightness(1.1);
}
  




.landing-quote-container{
    width: 95%;
    max-width: 950px;
    margin: auto;
    margin-top: 130px;
}
.landing-quote-container > p{
    font-size: 17px;
    font-weight: 400;
    font-family: monospace;
    /* margin-top: 20px; */
    /* text-align: center; */
    display: flex;
    align-items: flex-end;
}
.landing-quote-dot{
    display: flex;
    margin-bottom: 4px;
    margin-left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: var(--boken-green);
    box-shadow: 0 0 9px 2px rgba(0, 196, 0, 0.7);
}








.emoji-switch-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}

.emoji-switch-container>p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    height: 110px;
    margin: 0;
    cursor: pointer;
}

.emoji-shadow {
    width: 60px;
    height: 12px;
    border-radius: 50%;
    margin-top: 15px;
    /* margin-top: -23px; */
    margin-left: 1px;
    z-index: -10;
}

body[data-theme="light"] .emoji-shadow {
    background-color: rgb(231, 231, 231);
}

body[data-theme="dark"] .emoji-shadow {
    background-color: rgb(39, 39, 39);
}





















@media only screen and (max-width: 1000px) {


    .landing-top-bar {
        flex-direction: column;
        align-items: center;
        margin: 0;
    }

    .landing-left {
        width: 80%;
    }

    .landing-left-h1 {
        text-align: center;
        margin: auto;
        margin-top: 50px;
        /* font-size: 45px; */
        width: 500px;
    }

    .landing-left-h1>span {
        color: var(--boken-green);
        line-height: 10px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .landing-left-p {
        width: 500px;
        text-align: center;
    }

    .call-to-action-row {
        width: 80%;
        justify-content: center;
        margin-top: 35px;
    }

    .landing-right {
        width: 80%;
        margin-top: 50px;
    }

    .landing-head-image {
        width: 200px;
    }


    .landing-quote-container{
        width: 80%;
        margin-top: 100px;
    }
    .landing-quote-container > p{
        font-size: 16px;
        font-weight: 400;
        font-family: monospace;
        /* text-align: center; */
    }


}



@media only screen and (max-width: 600px) {


    .landing-left {
        width: 91%;
    }

    .landing-left-h1 {
        text-align: center;
        width: 400px;
        margin: auto;
        margin-top: 50px;
        font-size: 38px;
    }
    .landing-left-h1>span {
        color: var(--boken-green);
        line-height: 5px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
    .landing-left-p {
        width: 100%;
        font-size: 13px;
        text-align: center;
    }
    .call-to-action-row {
        width: 100%;
        justify-content: center;
        margin-top: 20px;
    }
    .call-to-example {
        margin-left: 15px;
        font-size: 15px;
    }

    .landing-right {
        width: 85%;
        margin-top: 50px;
    }

    .landing-head-image {
        width: 200px;
    }



    .landing-data-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: auto;
        margin-top: 15px;
    }
    .landing-data-each{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* width: 30%; */
        margin-right: 5px;
    }
    .landing-data-top{
        font-size: 20px;
    }
    .landing-data-icon-lived{
        width: 9px;
        height: 9px;
        margin-bottom: 3px;
    }
    .landing-data-icon-visited{
        width: 9px;
        height: 9px;
        margin-bottom: 3px;
    }
    .landing-data-icon-transited{
        width: 9px;
        height: 9px;
        margin-bottom: 3px;
    }
    .landing-data-text{
        margin-left: 3px;
        font-size: 12px;
        font-weight: 400;
    }



    
    .landing-quote-container{
        width: 85%;
        margin-top: 100px;
    }
    .landing-quote-container > p{
        font-size: 15px;
        font-weight: 400;
        font-family: monospace;
        /* text-align: center; */
    }

}


@media only screen and (max-width: 400px) {
    .landing-left-h1 {
        text-align: center;
        width: 100%;
        margin: auto;
        margin-top: 50px;
        font-size: 37px;
    }
}