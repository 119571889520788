




.info-link-each{
    margin-top: 10px;
}







body[data-theme="light"] .about-link{
    color: var(--light-text);
}
body[data-theme="dark"] .about-link{
    color: var(--dark-text);
}
.about-map-image{
    width: 90%;
    max-width: 400px;
    margin: auto;
    margin-top: 25px;
    display: flex;
    border-radius: 5px;
}
.about-figcaption{
    font-size: 13px;
    font-weight: 400;
    text-align: center;
}



.about-embed-iframe{
    max-width: 500px;
    max-height: 250px;
    aspect-ratio: 2/1;
}





.about-get-started-container{
    display: flex;
    justify-content: center;
    margin-top: 70px;
}
.about-get-started-button{
    width: 150px;
    /* height: 37px; */
    height: 150px;
    background-color: var(--boken-green);
    border-radius: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    /* font-family: monospace; */
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.about-get-started-button:hover{
    filter: brightness(1.2);
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}