/* html{
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
}
body{
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
} */
    
body.embed-page html {
    width: 100vw;
    height: 100vh;
}
body.embed-page {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
}
body.embed-light-body{
    /* background-color: #f1f3f3 !important; */
}
.embed-light-body[data-theme="light"] {
    background-color: #f1f3f3 !important;
}
.embed-light-body[data-theme="dark"] {
    background-color: #f1f3f3 !important;
}
.embed-dark-body[data-theme="light"] {
    background-color: #303030 !important;
}
.embed-dark-body[data-theme="dark"] {
    background-color: #303030 !important;
}
body.embed-page > #root{
    width: 100%;
    height: 100%;
}
body.embed-page > #root > .app{
    width: 100%;
    height: 100%;
    padding: 0;
}
body.embed-page > #root > .app > .main{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
body.embed-page > #root > .app > .main > div {
    position: relative;
    width: 100%;
    /* height: 100%; */
    max-height: 100%;
    /* max-width: 800px; */
    /* height: 100% !important; */
    /* height: 300px; */
    /* border-radius: 10px; */
    aspect-ratio: 13/6 !important;
    margin: auto;
}
.amcharts-embed-container{
    height: 100%;
    height: 100%;
    /* aspect-ratio: 13/6 !important; */
    position: relative;
    margin: auto;
}
.amcharts-embed{
    width: 100%;
    height: 100%;
    /* aspect-ratio: 13/6 !important; */
}



.embed-cats-container{
    position: absolute;
    /* bottom: min(6.5vw, 6.5vh); */
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: last baseline;
}



.embed-logo-container{
    position: absolute;
    /* top: 0; */
    bottom: 4px;
    right: 4px;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    display: flex;
    align-items: last baseline;
    cursor: pointer;

    padding: 4px 6px;
    /* padding: min(1.5vw, 1.5vh); */
    border-radius: 5px;
}
.embed-logo-icon{
    width: min(1.5vw, 1.5vh);
    height: min(1.5vw, 1.5vh);
    background-color: var(--boken-green);
    border-radius: 500px;
}
.embed-logo-text{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* font-size: 15px; */
    font-weight: 400;
    margin-left: 2px;
    font-size: min(2.5vw, 2.5vh);
}
.embed-cats-container{
    position: absolute;
}








.city-map-embed{
    /* width: 100vw; */
    /* height: 100vh; */
    width: 100%;
    height: 100%;
}
body[data-theme="dark"] .city-map-embed{
    /* filter: brightness(0.63); */
}
body[data-theme="light"] .city-map-embed{
    /* filter: brightness(1.08); */
}