




.plus-pricing-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
}



.current-button{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    font-size: 15px;
    width: 100px;
    height: 35px;
    margin-top: 30px;
}
body[data-theme="light"] .current-button{
    background-color: var(--light-gray);
    color: black;
}
body[data-theme="dark"] .current-button{
    background-color: rgb(63, 63, 63);
    color: white;
}






.dashboard-plus-button-container{
    display: flex;
    justify-content: center;
}
.dashboard-plus-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 45px;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    border-radius: 5px;
}
.dashboard-plus-button:hover{
    filter: brightness(1.2);
}
body[data-theme="light"] .dashboard-plus-button{
    background-color: var(--boken-green);
    color: white;
}
body[data-theme="dark"] .dashboard-plus-button{
    background-color: var(--boken-green);
    color: white;
}















.color-option-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 65px;
    /* height: 90px; */
    /* border: 1px solid rgb(130, 130, 130); */
    border-radius: 6px;
    margin-right: 10px;
}
.color-option {
    width: 50px;
    height: 50px;
    border-radius: 500%;
    cursor: pointer;
}
.option-green {
    background: conic-gradient(
        var(--color-transited-green) 0deg 120deg, 
        var(--color-visited-green) 120deg 240deg, 
        var(--color-lived-green) 240deg 360deg
    );
}
.option-blue{
    background: conic-gradient(
        var(--color-transited-blue) 0deg 120deg, 
        var(--color-visited-blue) 120deg 240deg, 
        var(--color-lived-blue) 240deg 360deg
    );
}
.option-red{
    background: conic-gradient(
        var(--color-transited-red) 0deg 120deg, 
        var(--color-visited-red) 120deg 240deg, 
        var(--color-lived-red) 240deg 360deg
    );
}
.option-purple{
    background: conic-gradient(
        var(--color-transited-purple) 0deg 120deg, 
        var(--color-visited-purple) 120deg 240deg, 
        var(--color-lived-purple) 240deg 360deg
    );
}
.option-brown{
    background: conic-gradient(
        var(--color-transited-brown) 0deg 120deg, 
        var(--color-visited-brown) 120deg 240deg, 
        var(--color-lived-brown) 240deg 360deg
    );
}

.color-option-check {
    margin-top: 7px;
}

.plus-map-container {
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
}






.dashboard-status-icon{
    display: flex;
    margin-left: 10px;
    margin-right: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
body[data-theme="light"] .dashboard-status-icon{
    background-color: rgb(8, 236, 8);
    box-shadow: 0 0 8px 1px rgba(8, 238, 8, 0.7);
}
body[data-theme="dark"] .dashboard-status-icon{
    background-color: rgb(0, 196, 0);
    box-shadow: 0 0 8px 1px rgba(0, 196, 0, 0.7);
}
.dashboard-manage-subscription-container{
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.dashboard-manage-subscription-button{
    width: 190px;
    height: 45px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}
.dashboard-manage-subscription-button:hover{
    filter: brightness(1.2);
}
body[data-theme="light"] .dashboard-manage-subscription-button{
    background-color: rgb(11, 11, 11);
    color: white;
}
body[data-theme="dark"] .dashboard-manage-subscription-button{
    background-color: rgb(238, 238, 238);
    color: black;
}



@media only screen and (max-width: 700px) {
    .color-option-container {
        width: 40px;
        border-radius: 6px;
        margin-right: 15px;
    }
    .color-option {
        width: 35px;
        height: 35px;
    }
}