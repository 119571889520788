


body.create-page > #root {
    display: flex;
    flex-direction: column;
}
body.create-page > #root > .app {
    padding-bottom: 0;
    /* min-height: 450px; */
}


.create-header{
    margin-top: 40px;
}
.create-account-title{
    margin: 0;
    margin-top: 5px;
    font-family: monospace;
    font-weight: 300;
    /* font-size: 20px; */
    text-align: center;
}


.create-container{
    display: flex;
    flex-direction: column;
    width: 330px;
    margin: auto;
    margin-top: 70px;
    border-radius: 10px;
}
body[data-theme="light"] .create-container{
    background-color: var(--light-gray);
}
body[data-theme="dark"] .create-container{
    background-color: var(--dark-gray);
}

.create-handle-title{
    display: flex;
    justify-content: left;
    margin: 0;
    font-size: 22px;
    width: 280px;
    margin: auto;
    margin-top: 30px;
    font-weight: 500;
}
.create-handle-warning{
    display: flex;
    justify-content: left;
    margin: 0;
    margin-top: 0;
    font-size: 14px;
    font-weight: 400;
    width: 280px;
    margin: auto;
}





.create-error-row{
    display: flex;
    height: 35px;
    width: 280px;
    margin: auto;
    margin-top: 8px;
}
.create-error-text{
    height: 35px;
    font-size: 13px;
    margin: 0;
}
body[data-theme="light"] .create-error-text{
    color: red;
}
body[data-theme="dark"] .create-error-text{
    color: rgb(255, 25, 25);
}



.create-input-row{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
}
.create-handle-domain{
    font-size: 16px;
    font-weight: 400;
}
body[data-theme="light"] .create-handle-domain{
    color: rgb(65, 65, 65);
}
body[data-theme="dark"] .create-handle-domain{
    color: rgb(216, 216, 216);
}
.create-handle-input{
    width: 145px;
    height: 27px;
    border-radius: 6px;
    margin-left: 7px;
    font-size: 14px;
}
body[data-theme="light"] .create-handle-input{
    background-color: white;
    border: 1px solid rgb(206, 206, 206);
}
body[data-theme="dark"] .create-handle-input{
    background-color: rgb(235, 235, 235);
    border: 1px solid rgb(206, 206, 206);

}



.create-terms-row{
    width: 280px;
    margin: auto;
    margin-top: 34px;
}
.create-terms-text{
    font-size: 13px;
}
.create-terms-link{
    /* text-decoration: none; */
}
body[data-theme="light"] .create-terms-link{
    color: rgb(35, 35, 35);
}
body[data-theme="dark"] .create-terms-link{
    color: rgb(255, 255, 255);
}
.create-terms-link:hover{
    text-decoration: underline;
}




.create-button-row{
    display: flex;
    justify-content: right;
    width: 280px;
    margin: auto;
    margin-top: 28px;
    margin-bottom: 30px;
}
.create-submit-button{
    width: 135px;
    height: 37px;
    border: none;
    border-radius: 5px;
    background-color: var(--boken-green);
    color: white;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
}


.create-cancel-link{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: gray !important;
    font-size: 14px;
    font-weight: 400;
    text-decoration-style: dotted;
    margin-bottom: 50px;
}



