
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    margin-left: 22px;
    margin-right: 25px;
}

.nav-left {
    display: flex;
    align-items: baseline;
    cursor: pointer;
}
.nav-left-icon {
    background-color: green;
    width: 14px;
    height: 14px;
    border-radius: 100%;
}
.nav-left-name{
    margin: 0;
    margin-left: 2px;
    font-size: 24px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.nav-right {
    display: flex;
    align-items: center;
    position: relative;
}
.nav-right-theme-toggle-container{
    width: 30px;
    height: 30px;
    border: 1px solid gray;
    border-radius: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;
}
.nav-right-theme-toggle-container:hover{
    filter: brightness(1.3);
}
.nav-right-theme-toggle{
    font-size: 17px;
}
/* .nav-right-edit {
    margin-right: 10px;
    font-size: 20px;
} */
.nav-right-login {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 70px;
    height: 33px;
    /* background-color: rgb(253, 253, 253); */
    /* border: 1px solid rgb(201, 201, 201); */
    border-radius: 20px;
    text-decoration: none;
    font-weight: 400;
}
body[data-theme="light"] .nav-right-login{
    /* background-color: var(--boken-green); */
    background-color: var(--dark-gray);
    color: white;
}
body[data-theme="dark"] .nav-right-login{
    /* background-color: var(--boken-green); */
    background-color: var(--light-gray);
    color: black;
}
.nav-right-login:hover{
    /* background-color: #f9f5f5; */
    /* border: 1px solid rgb(160, 160, 160); */
    filter: brightness(1.2);
}

.nav-right-account{
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
    transition: transform 0.05s ease;
}
.nav-right-account:hover{
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
    /* transform: scale(1.015); */
}
body[data-theme="light"] .nav-right-account{
    background-color: var(--light-gray); /*画像のロード前、未ロード時に使う背景 */
}
body[data-theme="dark"] .nav-right-account{
    background-color: var(--dark-gray);
}
body[data-theme="light"] .account-menu{
    background-color: rgb(253, 254, 254);
    border: 1px solid #d6d6d6;
}
body[data-theme="dark"] .account-menu{
    background-color: var(--dark-gray);
    border: 1px solid rgb(62, 62, 62);
}
.account-menu {
    position: absolute;
    top: 50px;
    right: 0px;
    /* width: 120px; */
    padding-top: 4px;
    padding-bottom: 4px;
    /* background-color: rgb(253, 254, 254); */
    border-radius: 11px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.account-menu-option{
    width: 115px;
    height: 34px;
    display: flex;
    align-items: center;
    font-size: 15.5px;
    font-weight: 400;
    /* padding: 15px; */
    padding-left: 10px;
    margin: 1px 5px;
    cursor: pointer;
    border-radius: 6px;

    /* テキストのセレクトを無効化 */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.liner{
    width: 135px;
    margin: 4px auto 4px auto;
}
body[data-theme="light"] .liner{
    border-top: 1px solid #d6d6d6;
}
body[data-theme="dark"] .liner{
    border-top: 1px solid rgb(63, 63, 63);
}
body[data-theme="light"] .account-menu-option:hover{
    background-color: rgb(236, 236, 236);
}
body[data-theme="dark"] .account-menu-option:hover{
    background-color: rgb(82, 82, 82);
}



@media (min-width: 900px) {
    .nav{
        width: 95%;
        max-width: 1000px;
        margin: auto;
    }
}

@media (max-width: 1000px) {
    .account-menu{
        right: -8px;
    }
}