

.footer{
    /* background-color: rgb(239, 244, 244); */
    /* display: flex; */
    /* justify-content: space-between; */
    width: 100%;
    height: 250px;
    position: absolute;/*←絶対位置*/
    bottom: 0; /*下に固定*/
}
body[data-theme="light"] footer{
    background-color: var(--light-gray);
}
body[data-theme="dark"] footer{
    background-color: var(--dark-gray);
}
body[data-theme="light"] .footer-text{
    color: var(--light-text);
}
body[data-theme="dark"] .footer-text{
    color: var(--dark-text);
}

.footer-top{
    display: flex;
}
.footer-left{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
.footer-left-logo{
    display: flex;
    align-items: baseline;
    margin: 5px auto 0px 30px;
    cursor: pointer;
}
.footer-left-icon{
    width: 12px;
    height: 12px;
    background-color: var(--boken-green);
    border-radius: 100%;
}
.footer-left-name{
    font-size: 20px;
    font-weight: 600;
    margin-left: 2px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.footer-login{
    width: 70px;
    height: 30px;
    border: 1px solid gray;
    border-radius: 50px;
    text-decoration: none;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px auto auto 30px;
}
body[data-theme="light"] .footer-login:hover{
    border-color: rgb(90, 90, 90);
}
body[data-theme="dark"] .footer-login:hover{
    border-color: rgb(184, 184, 184);
}
.footer-left-row{
    display: flex;
    margin: 5px auto auto 30px;
}
.footer-handle{
    font-size: 15px;
    text-decoration: none;
    margin-right: 3px;
}
body[data-theme="light"] .footer-handle{
    color: gray;
}
body[data-theme="dark"] .footer-handle{
    color: gray;
}
body[data-theme="light"] .footer-handle:hover{
    color: var(--light-text)
}
body[data-theme="dark"] .footer-handle:hover{
    color: var(--dark-text)
}
.footer-logout{
    /* width: 70px; */
    /* height: 30px; */
    /* border: 1px solid gray; */
    /* border-radius: 50px; */
    text-decoration: none;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
}
body[data-theme="light"] .footer-logout{
    color: gray
}
body[data-theme="dark"] .footer-logout{
    color: gray
}
body[data-theme="light"] .footer-logout:hover{
    color: var(--light-text)
}
body[data-theme="dark"] .footer-logout:hover{
    color: var(--dark-text)
}



.footer-right{
    display: flex;
    flex-direction: column;
    margin: 30px 50px auto auto;
}
.footer-right > a{
    text-decoration: none;
    /* color: rgb(90, 90, 90); */
    margin-top: 12px;
    filter: brightness(0.9);
}
.footer-right > a:hover{
    /* text-decoration: underline; */
    filter: brightness(1.9);
}






.footer-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}
.footer-copyright{
    margin: 0;
    font-size: 10px;
    font-weight: 300;
    /* color: rgb(72, 72, 72); */
}
.footer-credit{
    margin-top: 0px;
    font-size: 6px;
    cursor: pointer;
    text-decoration: underline;
    /* color: rgb(115, 115, 115); */
}







@media (min-width: 900px) {
    .footer-top{
        width: 95%;
        max-width: 1000px;
        margin: auto;
    }
}