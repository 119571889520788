:root{
  --light-back: rgb(255, 255, 255);
  --dark-back: rgb(24, 24, 24);
  /* #181818 */

  /* --light-gray: rgb(241, 243, 243); */
  --light-gray: #f4f4f4;
  /* #f1f3f3 */
  --dark-gray: #303030;
  color: #cdcdcd;

  --light-text: black;
  --dark-text: rgb(231, 231, 231);

  --light-red: rgb(231, 0, 0);
  --dark-red: rgb(255, 40, 40);

  --boken-green: #0d780d;
  /* #0d780d */

  --color-lived-green: #005403;
  --color-visited-green: #00cf18;
  --color-transited-green: #bbff00;
  --color-ui-green: #0d780d;

  --color-lived-blue: #093788;
  --color-visited-blue: #3069e4;
  --color-transited-blue: #71d7ff;
  --color-ui-blue: #0b40d1;

  --color-lived-red: #a20606;
  --color-visited-red: #f14a4a;
  --color-transited-red: #ff9a9a;
  --color-ui-red: #ee1d1d;

  --color-lived-purple: #71025d;
  --color-visited-purple: #c632c1;
  --color-transited-purple: #f49dff;
  --color-ui-purple: #aa099d;

  --color-lived-brown: #642f00;
  --color-visited-brown: #b86318;
  --color-transited-brown: #ffbd6d;
  --color-ui-brown: #834d06;

  --rt-opacity: 1 !important;
}

html{
}

body{
  /* font-family: sans-serif, monospace; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  /* font-family: monospace; */
}

body[data-theme="light"]{
  background-color: var(--light-back);
  color: var(--light-text);
}
/* body[data-theme="light"] footer{
  background-color: var(--light-gray);
  color: var(--light-text);
} */
body[data-theme="dark"]{
  background-color: var(--dark-back);
  color: var(--dark-text)
}
body[data-theme="light"] a{
  color: var(--boken-green);
  text-decoration-skip-ink: none;
}
body[data-theme="dark"] a{
  color: var(--boken-green);
  text-decoration-skip-ink: none;
}




.hover-popup-parent{
}
.hover-popup-parent > div{
  margin: 0;
  padding: 0px 5px auto 5px;
}
.hover-popup-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0;
  margin-bottom: 0px;
  padding: 0;
  height: 10px;
}
.hover-popup-city{
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.hover-popup-country{
  color: black;
  font-size: 13px;
  margin: 0;
  margin-top: -2px;
  padding: 0;
}