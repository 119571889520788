/* Skeleton Loading作りかけてやめた */
/* .skeleton-page{
    height: 100vh;
}
.skeleton-map-container{
    width: 100%;
    max-width: 800px;
    margin: auto;
    aspect-ratio: 13/6;
}
.skeleton-map{

}
.skeleton-bar{
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
    margin: auto;
    margin-top: 10px;
}
.skeleton-image{
    width: 100px;
    height: 100px;
}
.skeleton-data{
    width: 300px;
    height: 100px;
    border-radius: 10px;
} */














.home-profile{
    margin: auto 17px 60px 17px;
}

.top-strip{
    width: 100%;
    max-width: 885px;
    margin: auto;
    display: flex;
    justify-content: space-between;
}
.home-switch{
    width: 160px;
    height: 28px;
    /* padding-left: 5px;
    padding-right: 5px; */
    border-radius: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.type-select{
    display: none;
}
body[data-theme="light"] .home-switch{
    background-color: var(--light-gray);
}
body[data-theme="dark"] .home-switch{
    background-color: var(--dark-gray);
}
.country-tab{
    background: none;
    cursor: pointer;
    border: none;
    /* border-radius: 50px; */
    width: 90px;
    height: 28px;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
body[data-theme="light"] .country-tab{
    color: black;
}
body[data-theme="dark"] .country-tab{
    color: white;
}
.city-tab{
    background: none;
    cursor: pointer;
    border: none;
    /* border-radius: 50px; */
    width: 70px;
    height: 28px;
    font-size: 13px;
    font-weight: 500;
    position: relative; /* Needed for transition effect */
    z-index: 1; /* Ensure buttons are above the background */
    display: flex;
    justify-content: center;
    align-items: center;
}
body[data-theme="light"] .city-tab{
    color: black;
}
body[data-theme="dark"] .city-tab{
    color: white;
}
.active{
    background-color: var(--boken-green);
    color: white !important;
    height: 28px;
    border-radius: 300px;
    transition: background-color 0.3s ease, color 0.3s ease;
}



.indicator {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 95px; */
    height: 28px;
    background-color: var(--boken-green);
    border-radius: 300px;
    transition: transform 0.3s ease, width 0.3s ease; /* Smooth transition for position */
}
.country-tab.active ~ .indicator {
    transform: translateX(0); /* Active tab (Countries) */
    width: 90px;
}
.city-tab.active ~ .indicator {
    transform: translateX(95px); /* Shift for City tab */
    width: 60px;
}









.map-container{
    width: 100%;
    max-width: 900px;
    /* height: 300px; */
    /* background-color: var(--dark-gray); */
    margin: auto;
    margin-top: 7px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 13/6;
    position: relative;
}
body[data-theme="light"] .map-container{
    background-color: var(--light-gray);
    /* border: 1px solid rgb(228, 228, 228); */
}
body[data-theme="dark"] .map-container{
    background-color: var(--dark-gray);
}
.amcharts-map{
    width: 100%;
    /* max-width: 800px; */
    height: 100% !important;
    /* height: 300px; */
    /* border-radius: 10px; */
    aspect-ratio: 13/6;
}
.city-map{
    width: 100%;
    max-width: 900px;
    /* height: 300px; */
    /* border-radius: 10px; */
    aspect-ratio: 13/6;
}
body[data-theme="dark"] .city-map{
    filter: brightness(0.63);
}
body[data-theme="light"] .city-map{
    /* filter: brightness(1.08); */
}
.attribution-button{
    position: absolute;
    bottom: 5px;
    left: 8px;
}
.attribution-icon{
    width: 18px;
    height: 18px;
    cursor: pointer;
    opacity: 0.5;
}
.map-button-container{
    position: absolute;
    bottom: 7px;
    right: 7px;
    display: flex;
    justify-content: right;
    width: 200px;
}
body[data-theme="light"] .map-button-container > div{
    background-color: rgb(255, 255, 255);
    border: 0.5px solid rgb(207, 207, 207);
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px; */
}
body[data-theme="dark"] .map-button-container > div{
    background-color: var(--light-gray);
    background-color: rgb(0, 0, 0);
}
.map-button-container > div{
    width: 25px;
    height: 25px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
body[data-theme="light"] .map-button-container > div > svg{
    color: rgb(34, 34, 34);
}
body[data-theme="dark"] .map-button-container > div > svg{
    color: rgb(231, 231, 231);
}
/* .country-edit-button > img{
    width: 17px;
}
body[data-theme="light"] .country-edit-button > img{
    color: rgb(34, 34, 34);
}
body[data-theme="dark"] .country-edit-button > img{
    filter: invert(100%);
} */
.edit-icon{
    width: 12px;
}
.code-icon{
    width: 15px;
}
.image-icon{
    width: 15px;
}
.fullscreen-icon{
    width: 13px;
}










.fullscreen-left-container{
    position: absolute;
    top: 10px;
    left: 10px;
    width: 370px;
    height: 90%;
    border-radius: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    /* border: 1px solid rgb(72, 72, 72); */
    scrollbar-width: thin;
}
body[data-theme="light"] .fullscreen-left-container::-webkit-scrollbar {
    width: 8px;
    background-color: var(--light-back);
    border-radius: 10px;
}
body[data-theme="dark"] .fullscreen-left-container::-webkit-scrollbar {
    width: 8px;
    background-color: var(--dark-back);
    border-radius: 10px;
}
body[data-theme="light"] .fullscreen-left-container::-webkit-scrollbar-thumb {
    background-color: rgb(182, 182, 182); /* スライダーの色 */
    border-radius: 10px;
}
body[data-theme="dark"] .fullscreen-left-container::-webkit-scrollbar-thumb {
    background-color: gray; /* スライダーの色 */
    border-radius: 10px;
}
body[data-theme="light"] .fullscreen-left-container{
    background-color: var(--light-back);
    scrollbar-color: #888 var(--light-back);
    /* border: 1px solid rgb(207, 207, 207); */
}
body[data-theme="dark"] .fullscreen-left-container{
    background-color: var(--dark-back);
    scrollbar-color: #888 var(--dark-back); /* スライダーと背景色 */
}

.fullscreen-left-data{
    height: 90px;
    width: 90%;
    margin: auto;
    margin-top: 10px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
body[data-theme="light"] .fullscreen-left-data{
    background-color: var(--light-gray);
}
body[data-theme="dark"] .fullscreen-left-data{
    background-color: black;
}
.fullscreen-left-data-left{
    text-align: center;
    width: 100px;
}
.fullscreen-left-data-left-top{
    font-size: 25px;
}
.fullscreen-left-data-left-top > span{
    font-size: 14px;
}
.fullscreen-left-data-left-bottom{
    font-size: 15px;
}
.fullscreen-left-data-right{
    text-align: center;
    width: 100px;
}
.fullscreen-left-data-right-top{
    font-size: 25px;
}
.fullscreen-left-data-right-top > span{
    font-size: 14px;
}
.fullscreen-left-data-right-bottom{
    font-size: 15px;
}











.map-legend-container{
    /* width: 200px; */
    display: flex;
    align-items: flex-end;
    /* align-items: center; */
}
.map-legend{
    display: flex;
    justify-content: right;
    width: 100%;
}
.map-legend-each{
    display: flex;
    align-items: last baseline;
    margin-right: 8px;
}
.legend-icon{
    width: 11px;
    height: 11px;
    border-radius: 50px;
}
.legend-lived-icon{
    /* background-color: var(--color-lived); */
}
.legend-visited-icon{
    /* background-color: var(--color-visited); */
}
.legend-transited-icon{
    /* background-color: var(--color-transited); */
}
.legend-text{
    font-size: 13px;
    margin-left: 3px;
}

.cats-info-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
}
.cats-info-icon{
    cursor: pointer;
    opacity: 0.6;
    font-size: 12px;
    width: 13px;
    height: 12px;
}









.home-bar{
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 900px;
    height: 100px;
    margin: auto;
    margin-top: 30px;
}
.home-bar-left{
    display: flex;
    align-items: flex-end;
    height: 100%;
}
.home-bar-image{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    object-fit: cover;
}
.home-bar-left-buttons{
    display: flex;
    align-items: center;
}
.home-bar-edit{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
body[data-theme="light"] .home-bar-edit{
    background-color: rgb(243, 243, 243);
}
body[data-theme="dark"] .home-bar-edit{
    background-color: rgb(30, 30, 30);
}
.edit-profile-icon{
    font-size: 14px;
}
body[data-theme="light"] .edit-profile-icon{
    color: rgb(90, 90, 90);
}
body[data-theme="dark"] .edit-profile-icon{
    color: rgb(199, 199, 199);
}
.home-bar-share{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 5px;
}
body[data-theme="light"] .home-bar-share{
    background-color: rgb(243, 243, 243);
}
body[data-theme="dark"] .home-bar-share{
    background-color: rgb(30, 30, 30);
}
.share-profile-icon{
    font-size: 15px;
}
body[data-theme="light"] .share-profile-icon{
    color: rgb(90, 90, 90);
}
body[data-theme="dark"] .share-profile-icon{
    color: rgb(199, 199, 199);
}

.home-bar-data{
    width: 320px;
    height: 100px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
body[data-theme="light"] .home-bar-data{
    background-color: var(--light-gray);
    /* border: 1px solid rgb(228, 228, 228); */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px; */
}
body[data-theme="dark"] .home-bar-data{
    background-color: var(--dark-gray);
}
.country-info-container{
    position: absolute;
    top: 5px;
    right: 5px;
}
.country-info-icon{
    cursor: pointer;
    opacity: 0.65;
}
.home-bar-data-each{
    width: 110px;
}
.home-bar-data-each-top{
    text-align: center;
    margin: 0;
}
.home-bar-data-each-top > p{
    font-size: 33px;
    font-weight: 400;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.number-of-countries{
    font-size: 32px;
    font-weight: 400;
    margin: 0;
}
.home-bar-data-each-top > p > span{
    font-size: 15px;
    margin-bottom: 4px;
}
.home-bar-data-each-bottom{
    text-align: center;
    margin: 0;
    font-size: 15px;
}

.home-info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 900px;
    margin: auto;
    margin-top: 8px;
}
.home-left{
    flex-shrink: 1;
    margin-right: 10px;
}
.home-left-username{
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
}
.home-left-handle{
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
}
body[data-theme="light"] .home-left-handle{
    color: rgb(75, 75, 75);
}
body[data-theme="dark"] .home-left-handle{
    color: rgb(200, 200, 200);
}
.home-left-strip{
    display: flex;
    margin-top: 14px;
}
.home-left-follow{
    background-color: var(--boken-green);
    border: 0.5px solid rgba(0, 0, 0, 0);
    width: 68px;
    height: 33px;
    cursor: pointer;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
}
.home-left-share{
    /* background-color: rgb(231, 231, 231); */
    background-color: var(--light-gray);
    border: 0.5px solid rgba(0, 0, 0, 0);
    /* width: 33px; */
    width: 65px;
    height: 33px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
body[data-theme="light"] .home-left-share{
    border: 0.5px solid rgb(198, 198, 198);
}
body[data-theme="dark"] .home-left-share{
    color: black;
}
.home-left-share > svg{
    color: rgb(46, 46, 46);
    font-size: 19px;
}
.home-left-row{
    max-width: 350px;
    height: 28px;
    display: flex;
    align-items: flex-end;
    margin-top: 1px;
}
.home-left-row-desc{
    max-width: 350px;
    display: flex;
    align-items: last baseline;
    margin-top: 15px;
    margin-bottom: 7px;
}
.home-left-row-desc > p{
    margin: 0;
    /* margin-left: 5px; */
    font-size: 16px;
}
.home-left-icon{
    width: 13px;
    margin-bottom: 3px;
}
body[data-theme="light"] .home-left-icon{}
body[data-theme="dark"] .home-left-icon{
    filter: invert(1); /*白にする*/
}
.home-left-row > p{
    margin: 0;
    margin-left: 7px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.home-left-website{
    margin: 0;
    margin-left: 8px;
    font-size: 16px;
    text-decoration-skip-ink: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
body[data-theme="light"] .home-left-website{
    color: var(--boken-green);
}
body[data-theme="dark"] .home-left-website{
    color: var(--boken-green);
}
.no-info-edit-profile-button-container{
    margin-top: 20px;
    margin-left: 30px;
}
.no-info-edit-profile-button{
    width: 100px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    color: white;
}





.home-right{
    width: 320px;
    flex-shrink: 0;
    height: 170px;
    border-radius: 10px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
}
body[data-theme="light"] .home-right{
    background-color: var(--light-gray);
    /* border: 1px solid rgb(228, 228, 228); */
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px; */
}
body[data-theme="dark"] .home-right{
    background-color: var(--dark-gray);
}
.home-right-row{
    display: flex;
    align-items: center;
    width: 280px;
    height: 28px;
    /* margin: auto; */
    /* margin-top: 15px; */
    margin-left: 13px;
}
/* .progress-bar {
    height: 100%;
    width: 0;
    transition: width 0.5s ease-in-out;
  } */
.home-right-count-text{
    margin: 0;
    margin-left: 0px;
    font-size: 13px;
    font-weight: 300;
    width: 55px;
    text-align: center;
}
.home-right-count-text > span{
    font-size: 17px;
    font-weight: 600;
    margin-right: 1px;
}
.home-right-continent-text{
    width: 110px;
    margin: 0;
    margin-left: 8px;
    font-size: 15px;
    font-weight: 400;
}

.city-chart-container{
    width: 85%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.city-chart-canvas{
    width: 80%;
}















.home-country-list{
    margin-top: 80px;
}
.home-country-table{
    margin: auto;
    /* border-collapse: collapse; */
    border-collapse: separate;
    border-spacing: 0 0px;
}
.home-country-table > thead{}
thead:after {
	content: "";
	height: 1px;
	width: 100%;
	padding: 1px 0;
	display: block;
}
.home-country-table > thead > tr{
}
.home-country-table > thead > tr > th{
    border-bottom: 1px solid gray;
    font-size: 18px;
    padding-bottom: 3px;
    height: 35px;
}
.home-country-table > tbody{
}
.tbody{
}
.home-country-table > tbody > tr{
    height: 35px;
}
.home-country-table > tbody > tr > td{
    font-size: 17px;
    /* height: 27px; */
}
body[data-theme="light"] .tbody tr:nth-child(odd) {
    color: rgb(19, 19, 19);
}
body[data-theme="dark"] .tbody tr:nth-child(odd) {
    color: rgb(218, 218, 218);
}
body[data-theme="light"] .tbody tr:nth-child(even) > .country-td-status{
    border-radius: 5px 0px 0px 5px;
    background-color: #f0f0f0;
}
body[data-theme="light"] .tbody tr:nth-child(even) > .country-td-country{
    background-color: #f0f0f0;
}
body[data-theme="light"] .tbody tr:nth-child(even) > .country-td-continent{
    border-radius: 0px 5px 5px 0px;
    background-color: #f0f0f0;
}
body[data-theme="dark"] .tbody tr:nth-child(even) > .country-td-status{
    border-radius: 5px 0px 0px 5px;
    background-color: var(--dark-gray);
}
body[data-theme="dark"] .tbody tr:nth-child(even) > .country-td-country{
    background-color: var(--dark-gray);
}
body[data-theme="dark"] .tbody tr:nth-child(even) > .country-td-continent{
    border-radius: 0px 5px 5px 0px;
    background-color: var(--dark-gray);
}
.sort-icon{
    width: 15px;
}
.country-th-status{
    width:110px;
    cursor: pointer;
}
.country-th-country{
    width: 190px;
    text-align: left;
    cursor: pointer;
}
.country-th-continent{
    width: 160px;
    text-align: left;
    cursor: pointer;
}
.table-country-edit-button{
    width: 27px;
    height: 27px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
}
body[data-theme="light"] .table-country-edit-button{
    background-color: var(--light-gray);
}
body[data-theme="dark"] .table-country-edit-button{
    background-color: var(--dark-gray);
}
.table-country-edit-icon{
    width: 12px;
}
.country-td-status{
    text-align: center;
}
.status-icon{
    width: 16px;
    height: 16px;
    border-radius: 300px;
    margin: auto;
}
.status-one{
    background-color: var(--color-lived);
    /* width: 18px;
    height: 18px; */
}
.status-two{
    background-color: var(--color-visited);
    /* width: 13px;
    height: 13px; */
}
.status-three{
    background-color: var(--color-transited);
    /* width: 10px;
    height: 10px; */
}
.country-td-country{
    display: flex;
    justify-content: left;
    align-items: center;
    height: 35px;
    font-weight: 500;
}
.flag-emoji{
    /* width: 25px; */
    margin-right: 7px;
}
.country-td-continent{
}

.table-add-country-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
.table-add-country-button{
    width: 120px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    color: white;
}











.city-continent-container{
    width: 100%;
    max-width: 466px;
    margin: auto;
    margin-top: 80px;
    display: flex;
    justify-content: right;
}
.city-continent-select{
    border-radius: 5px;
    border: 0px;
    /* cursor: pointer; */
    /* height: 26px; */
    /* font-size: 14px; */
    /* padding-left: 5px; */
}
body[data-theme="light"] .city-continent-select{
    background-color: var(--light-gray);
}
body[data-theme="dark"] .city-continent-select{
    background-color: var(--dark-gray);
    color: var(--dark-text);
}
.city-continent-select > option{
    cursor: pointer;
}


.home-city-list{
    margin-top: 15px;
}
.home-city-table{
    margin: auto;
    /* border-collapse: collapse; */
    border-collapse: separate;
    border-spacing: 0 0px;
}
.home-city-table > thead{}
thead:after {
	content: "";
	height: 1px;
	width: 100%;
	padding: 1px 0;
	display: block;
}
.home-city-table > thead > tr{
}
.home-city-table > thead > tr > th{
    border-bottom: 1px solid gray;
    font-size: 18px;
    padding-bottom: 3px;
    height: 35px;
}
.home-city-table > tbody{
}
.tbody{
}
.home-city-table > tbody > tr{
    height: 35px;
}
.home-city-table > tbody > tr > td{
    font-size: 17px;
    /* height: 27px; */
}
body[data-theme="light"] .tbody tr:nth-child(odd) {
    color: rgb(19, 19, 19);
}
body[data-theme="dark"] .tbody tr:nth-child(odd) {
    color: rgb(218, 218, 218);
}
body[data-theme="light"] .tbody tr:nth-child(even) > .city-td-status{
    border-radius: 5px 0px 0px 5px;
    background-color: #f0f0f0;
}
body[data-theme="light"] .tbody tr:nth-child(even) > .city-td-city{
    background-color: #f0f0f0;
}
body[data-theme="light"] .tbody tr:nth-child(even) > .city-td-country{
    border-radius: 0px 5px 5px 0px;
    background-color: #f0f0f0;
}
body[data-theme="dark"] .tbody tr:nth-child(even) > .city-td-status{
    border-radius: 5px 0px 0px 5px;
    background-color: var(--dark-gray);
}
body[data-theme="dark"] .tbody tr:nth-child(even) > .city-td-city{
    background-color: var(--dark-gray);
}
body[data-theme="dark"] .tbody tr:nth-child(even) > .city-td-country{
    border-radius: 0px 5px 5px 0px;
    background-color: var(--dark-gray);
}

.city-th-status{
    width:110px;
    cursor: pointer;
}
.city-th-city{
    width: 210px;
    text-align: left;
    cursor: pointer;
}
.city-th-country{
    width: 160px;
    text-align: left;
    cursor: pointer;
}
.table-city-edit-button{
    width: 27px;
    height: 27px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}
body[data-theme="light"] .table-city-edit-button{
    background-color: var(--light-gray);
}
body[data-theme="dark"] .table-city-edit-button{
    background-color: var(--dark-gray);
}
.table-city-edit-icon{
    width: 12px;
}
body[data-theme="dark"] .sort-icon{
    filter: invert(100%);
}
.city-td-status{
    text-align: center;
}
.status-icon{
    width: 16px;
    height: 16px;
    border-radius: 300px;
    margin: auto;
}
.status-one{
    background-color: var(--color-lived);
    /* width: 18px;
    height: 18px; */
}
.status-two{
    background-color: var(--color-visited);
    /* width: 13px;
    height: 13px; */
}
.status-three{
    background-color: var(--color-transited);
    /* width: 10px;
    height: 10px; */
}
.city-td-city{
    display: flex;
    justify-content: left;
    align-items: center;
    height: 35px;
    font-weight: 500;
}
.flag-emoji{
    /* width: 25px; */
    margin-right: 7px;
}
.city-td-country{
    width: 160px;
}



.table-add-city-button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
.table-add-city-button{
    width: 100px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    color: white;
}














@media only screen and (max-width: 600px){
    .top-strip{
        width: 100%;
        max-width: 785px;
        margin: auto;
        display: flex;
        /* flex-direction: column-reverse; */
        justify-content: space-between;
    }
    .home-switch{
        /* align-self: flex-end; */
        display: none;
    }
    .type-select{
        display: block;
    }

    .map-container{
        aspect-ratio: 13/6;
    }

    .home-bar{
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: space-between;
    }
    .home-bar-image{
        width: 70px;
        height: 70px;
    }
    .home-bar-edit{
        width: 25px;
        height: 25px;
        border-radius: 5px;
    }
    .edit-profile-icon{
        font-size: 13px;
    }
    .home-bar-share{
        width: 25px;
        height: 25px;
        border-radius: 5px;
        margin-left: 5px;
    }
    .share-profile-icon{
        font-size: 13px;
    }





    .edit-profile-icon{
        font-size: 12px;
    }
    .home-bar-data{
        width: 210px;
        height: 70px;
    }
    .country-info-container{
        top: 2px;
        right: 3px;
    }
    .country-info-icon{
        cursor: pointer;
        opacity: 0.65;
        width: 15px;
    }
    .home-bar-data-each{
        width: 80px;
    }
    .home-bar-data-each-top{}
    .home-bar-data-each-top > p{
        font-size: 24px;
        font-weight: 500;
    }
    .home-bar-data-each-top > p > span{
        font-size: 11px;
        font-weight: 300;
    }
    .home-bar-data-each-bottom{
        font-size: 12px;
        font-weight: 300;
    }


    .home-info{
        flex-direction: column;
        margin-top: 5px;
    }
    .home-left{
    }
    .home-left-username{
        font-size: 26px;
    }
    .home-left-handle{
        font-size: 13px;
    }
    .home-left-strip{
        margin-bottom: 7px;
    }



    .home-left-follow{
    }
    .home-left-share{
    }
    .home-left-row{
        height: 25px;
    }
    .home-left-icon{
        width: 11px;
    }
    .home-left-row > p{
        font-size: 13px;
    }
    .home-left-row-desc > p{
        font-size: 13px;
    }
    .home-left-website{
        font-size: 13px;
    }

    
    .home-right{
        width: 330px;
        height: 170px;
        margin: auto;
        margin-top: 30px;
    }






    .home-country-list{
        /* margin-top: 40px; */
    }
    .home-country-table{
        /* margin: auto; */
        /* border-collapse: collapse; */
    }
    .home-country-table > thead{}
    .home-country-table > thead > tr{}
    .home-country-table > thead > tr > th{
        /* border-bottom: 1px solid gray; */
        font-size: 15px;
    }
    .home-country-table > tbody{}
    .home-country-table > tbody > tr{
        height: 30px;
    }
    .home-country-table > tbody > tr > td{
        font-size: 15px;
        height: 30px;
    }
    .sort-icon{
        width: 10px;
    }
    .country-th-status{
        width:90px;
    }
    .country-th-country{
        width: 160px;
    }
    .country-th-continent{
        width: 140px;
    }
    .country-td-status{
        /* text-align: center; */
    }
    .status-icon{
        width: 13px;
        height: 13px;
    }
    .country-td-country{}
    .country-td-continent{}














    .city-continent-container{
        width: 100%;
        max-width: 466px;
        margin: auto;
        margin-top: 40px;
        display: flex;
        justify-content: right;
    }
    .city-continent-select{
        border-radius: 5px;
        border: 0px;
        cursor: pointer;
        height: 25px;
        font-size: 14px;
        /* padding-left: 10px; */
    }
    .city-continent-select > option{
        cursor: pointer;
    }
    
    
    .home-city-list{
        margin-top: 15px;
    }
    .home-city-table{
        margin: auto;
        /* border-collapse: collapse; */
        border-collapse: separate;
        border-spacing: 0 0px;
    }
    .home-city-table > thead{}
    thead:after {
        content: "";
        height: 1px;
        width: 100%;
        padding: 1px 0;
        display: block;
    }
    .home-city-table > thead > tr{
    }
    .home-city-table > thead > tr > th{
        border-bottom: 1px solid gray;
        font-size: 17px;
        padding-bottom: 3px;
    }
    .home-city-table > tbody{
    }
    .tbody{
    }
    .home-city-table > tbody > tr{
        height: 30px;
    }
    .home-city-table > tbody > tr > td{
        font-size: 15px;
        height: 30px;
    }
    body[data-theme="light"] .tbody tr:nth-child(odd) {
        color: rgb(19, 19, 19);
    }
    body[data-theme="dark"] .tbody tr:nth-child(odd) {
        color: rgb(218, 218, 218);
    }
    body[data-theme="light"] .tbody tr:nth-child(even) > .city-td-status{
        border-radius: 5px 0px 0px 5px;
        background-color: #f0f0f0;
    }
    body[data-theme="light"] .tbody tr:nth-child(even) > .city-td-city{
        background-color: #f0f0f0;
    }
    body[data-theme="light"] .tbody tr:nth-child(even) > .city-td-country{
        border-radius: 0px 5px 5px 0px;
        background-color: #f0f0f0;
    }
    body[data-theme="dark"] .tbody tr:nth-child(even) > .city-td-status{
        border-radius: 5px 0px 0px 5px;
        background-color: var(--dark-gray);
    }
    body[data-theme="dark"] .tbody tr:nth-child(even) > .city-td-city{
        background-color: var(--dark-gray);
    }
    body[data-theme="dark"] .tbody tr:nth-child(even) > .city-td-country{
        border-radius: 0px 5px 5px 0px;
        background-color: var(--dark-gray);
    }
    
    .city-th-status{
        width:90px;
    }
    .city-th-city{
        width: 210px;
    }
    .city-th-country{
        width: 160px;
    }
    .sort-icon{
        width: 15px;
    }
    body[data-theme="dark"] .sort-icon{
        filter: invert(100%);
    }
    .city-td-status{
    }
    .status-one{
    }
    .status-two{
    }
    .status-three{
    }
    .city-td-city{
        font-weight: 500;
    }
    .city-td-country{
    }
}











@media only screen and (max-width: 450px) {
    .country-fullscreen-button{
        display: none !important;
    }
}








@media only screen and (max-width: 400px) {
    .home-profile{
        margin: auto 10px 60px 10px;
    }

    .top-strip{
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: space-between;
    }



    .map-legend-container{
        /* width: 200px; */
        display: flex;
        align-items: flex-end;
        /* align-items: center; */
    }
    .map-legend{
        display: flex;
        justify-content: right;
        width: 100%;
    }
    .map-legend-each{
        display: flex;
        align-items: flex-end;
        margin-right: 7px;
    }
    .legend-icon{
        width: 10px;
        height: 10px;
        border-radius: 50px;
        margin-bottom: 2px;
    }
    .legend-lived-icon{
        /* background-color: var(--color-lived); */
    }
    .legend-visited-icon{
        /* background-color: var(--color-visited); */
    }
    .legend-transited-icon{
        /* background-color: var(--color-transited); */
    }
    .legend-text{
        font-size: 11px;
        margin-left: 3px;
    }
    
    .cats-info-container{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 13px;
    }
    .cats-info-icon{
        cursor: pointer;
        opacity: 0.6;
        font-size: 12px;
        width: 12px;
        height: 11px;
    }






    .home-bar{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 75px;
        margin-top: 20px;
    }
    .home-bar-left{
    }
    .home-bar-image{
        width: 75px;
        height: 75px;
    }
    .home-bar-left-buttons{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        width: 35px;
        height: 75px;
    }
    .home-bar-edit{
    }
    .edit-profile-icon{
    }
    .home-bar-share{
        margin-left: 0;
    }
    .share-profile-icon{
    }

    .home-bar-data{
        width: 200px;
        height: 75px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .country-info-container{
        position: absolute;
        top: 2px;
        right: 3px;
    }
    .country-info-icon{
        cursor: pointer;
        opacity: 0.65;
        font-size: 13px;
    }
    .home-bar-data-each{
        width: 80px;
    }
    .home-bar-data-each-top{
        text-align: center;
        margin: 0;
    }
    .home-bar-data-each-top > p{
        font-size: 26px;
        font-weight: 400;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .number-of-countries{
        /* font-size: 32px; */
        font-weight: 400;
        margin: 0;
    }
    .home-bar-data-each-top > p > span{
        font-size: 12px;
        margin-bottom: 4px;
    }
    .home-bar-data-each-bottom{
        text-align: center;
        margin: 0;
        font-size: 12px;
    }





    .home-info{
        margin-top: 10px;
        width: 98%;
    }
    .home-left{
        margin-right: 0;
    }
    .home-left-username{
        font-size: 31px;
    }
    .home-left-handle{
        font-size: 15px;
    }
    .home-left-row-desc{
    }
    .home-left-row-desc > p{
        font-size: 16px;
    }
    .home-left-row{
        margin-top: 2px;
    }
    .home-left-icon{
        width: 14px;
        margin-bottom: 2px;
    }
    .home-left-row > p{
        font-size: 16px;
        margin-left: 8px;
    }
    .home-left-website{
        font-size: 16px;
    }











    .home-right{
        width: 95%;
        flex-shrink: 0;
        height: auto;
        min-height: 145px;
        border-radius: 10px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .home-right-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        height: 25px;
        /* margin: auto; */
        /* margin-top: 15px; */
        margin-left: 15px;
    }
    .MuiBox-root{
        height: 10px;
    }
    .home-right-progress{
        /* width: 40%; */
    }
    .home-right-count-text{
        margin: 0;
        margin-left: 6px;
        font-size: 10px;
        font-weight: 300;
        width: 15%;
        max-width: 40px;
        text-align: center;
    }
    .home-right-count-text > span{
        font-size: 16px;
        font-weight: 600;
        margin-right: 1px;
    }
    .home-right-continent-text{
        width: 40%;
        max-width: 120px;
        margin: 0;
        margin-left: 8px;
        font-size: 13px;
        font-weight: 400;
    }
    
    .city-chart-container{
        height: 145px;
        width: 89%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(0.93);
    }
    .city-chart-canvas{
        width: 75%;
        height: 100%;
    }









    .home-country-list{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin-top: 20px;
        /* transform: scale(0.85); */
        /* transform-origin: top; */
    }
    .home-country-table-wrapper{
        transform: scale(0.89);
        transform-origin: top;
    }
    .home-country-table{
        /* transform: scale(0.85); */
    }





    .city-continent-container{
        margin-top: 25px;
        height: 30px;
        width: 92.5%;
    }
    .home-city-list{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        /* transform: scale(0.85); */
        /* transform-origin: top; */
        margin-top: 10px;
    }
    .home-city-table-wrapper{
        transform: scale(0.85);
        transform-origin: top;
    }
    .home-city-table{
        /* transform: scale(0.85); */
    }
}








@media only screen and (max-width: 300px) {
    .home-profile{
        margin: auto 10px 60px 10px;
    }

    .top-strip{
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: space-between;
    }



    .map-legend-container{
        /* width: 200px; */
        display: flex;
        align-items: flex-end;
        /* align-items: center; */
    }
    .map-legend{
        display: flex;
        justify-content: right;
        width: 100%;
    }
    .map-legend-each{
        display: flex;
        align-items: flex-end;
        margin-right: 7px;
    }
    .legend-icon{
        width: 10px;
        height: 10px;
        border-radius: 50px;
        margin-bottom: 2px;
    }
    .legend-lived-icon{
        /* background-color: var(--color-lived); */
    }
    .legend-visited-icon{
        /* background-color: var(--color-visited); */
    }
    .legend-transited-icon{
        /* background-color: var(--color-transited); */
    }
    .legend-text{
        font-size: 11px;
        margin-left: 3px;
    }
    
    .cats-info-container{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 13px;
    }
    .cats-info-icon{
        cursor: pointer;
        opacity: 0.6;
        font-size: 12px;
        width: 12px;
        height: 11px;
    }






    .home-bar{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 70px;
        margin-top: 20px;
    }
    .home-bar-left{
    }
    .home-bar-image{
        width: 70px;
        height: 70px;
    }
    .home-bar-left-buttons{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        width: 35px;
        height: 70px;
    }
    .home-bar-edit{
    }
    .edit-profile-icon{
    }
    .home-bar-share{
        margin-left: 0;
    }
    .share-profile-icon{
    }

    .home-bar-data{
        width: 195px;
        height: 70px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .country-info-container{
        position: absolute;
        top: 2px;
        right: 3px;
    }
    .country-info-icon{
        cursor: pointer;
        opacity: 0.65;
        font-size: 13px;
    }
    .home-bar-data-each{
        width: 80px;
    }
    .home-bar-data-each-top{
        text-align: center;
        margin: 0;
    }
    .home-bar-data-each-top > p{
        font-size: 25px;
        font-weight: 400;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .number-of-countries{
        /* font-size: 32px; */
        font-weight: 400;
        margin: 0;
    }
    .home-bar-data-each-top > p > span{
        font-size: 12px;
        margin-bottom: 4px;
    }
    .home-bar-data-each-bottom{
        text-align: center;
        margin: 0;
        font-size: 12px;
    }





    .home-info{
        margin-top: 10px;
        width: 98%;
    }
    .home-left{
        margin-right: 0;
    }
    .home-left-username{
        font-size: 32px;
    }
    .home-left-handle{
        font-size: 15px;
    }
    .home-left-row-desc{
    }
    .home-left-row-desc > p{
        font-size: 15px;
    }
    .home-left-row{}
    .home-left-icon{
        width: 13px;
        margin-bottom: 2px;
    }
    .home-left-row > p{
        font-size: 15px;
        margin-left: 8px;
    }
    .home-left-website{
        font-size: 15px;
    }











    .home-right{
        width: 95%;
        flex-shrink: 0;
        height: auto;
        min-height: 145px;
        border-radius: 10px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .home-right-row{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        height: 25px;
        /* margin: auto; */
        /* margin-top: 15px; */
        margin-left: 15px;
    }
    .MuiBox-root{
        height: 10px;
    }
    .home-right-progress{
        /* width: 40%; */
    }
    .home-right-count-text{
        margin: 0;
        margin-left: 6px;
        font-size: 10px;
        font-weight: 300;
        width: 15%;
        max-width: 40px;
        text-align: center;
    }
    .home-right-count-text > span{
        font-size: 16px;
        font-weight: 600;
        margin-right: 1px;
    }
    .home-right-continent-text{
        width: 40%;
        max-width: 120px;
        margin: 0;
        margin-left: 8px;
        font-size: 13px;
        font-weight: 400;
    }
    
    .city-chart-container{
        height: 145px;
        width: 89%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(0.93);
    }
    .city-chart-canvas{
        width: 75%;
        height: 100%;
    }









    .home-country-list{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin-top: 20px;
        /* transform: scale(0.85); */
        /* transform-origin: top; */
    }
    .home-country-table-wrapper{
        transform: scale(0.89);
        transform-origin: top;
    }
    .home-country-table{
        /* transform: scale(0.85); */
    }





    .city-continent-container{
        margin-top: 25px;
        height: 30px;
        width: 92.5%;
    }
    .home-city-list{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        /* transform: scale(0.85); */
        /* transform-origin: top; */
        margin-top: 10px;
    }
    .home-city-table-wrapper{
        transform: scale(0.85);
        transform-origin: top;
    }
    .home-city-table{
        /* transform: scale(0.85); */
    }
}