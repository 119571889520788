

.share-page{
    /* margin: auto; */
    margin-bottom: 80px;
    /* width: 90%; */
    /* max-width: 800px; */
}


.back-icon-container{
    width: 95%;
    max-width: 1000px;
    margin: auto;
}
.back-icon{
    /* position: absolute; */
    /* top: 80px; */
    /* left: 40px; */
    margin-left: 20px;
    font-size: 20px;
    cursor: pointer;
}



.share-profile{
    width: 270px;
    height: 300px;
    margin: auto;
    margin-top: 5vh;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
body[data-theme="light"] .share-profile{
    background-color: var(--light-gray);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
body[data-theme="dark"] .share-profile{
    background-color: var(--dark-gray);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.share-qrcode-container{
    width: 260px;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-top: 30px;
}
body[data-theme="light"] .share-qrcode-container{}
body[data-theme="dark"] .share-qrcode-container{
}
.share-qrcode{
    width: 160px;
    height: 160px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0);
}
body[data-theme="light"] .share-qrcode:hover{
    border: 1px dashed var(--boken-green);
}
body[data-theme="dark"] .share-qrcode:hover{
    border: 1px dashed #07a707;
}
.share-at{
    margin-top: 5px;
    font-size: 23px;
    font-family: sans-serif;
    font-family: monospace;
    font-weight: 400;
    /* font-family: Lora; */
}
body[data-theme="light"] .share-at{
    color: var(--boken-green);
}
body[data-theme="dark"] .share-at{
    color: #07a707;
}










.share-logo{
    display: flex;
    align-items: last baseline;
    margin-bottom: 10px;
    user-select: none;
}
.share-logo-icon{
    width: 10px;
    height: 10px;
    background-color: var(--boken-green);
    border-radius: 50px;
}
.share-logo-title{
    font-size: 17px;
    font-weight: 500;
    margin-left: 2px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}








.share-bottom-row{
    width: 270px;
    margin: auto;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}
.share-bottom-left{
    width: 47%;
    height: 70px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
}
body[data-theme="light"] .share-bottom-left{
    background-color: var(--light-gray);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
body[data-theme="dark"] .share-bottom-left{
    background-color: var(--dark-gray);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.share-bottom-left:hover{
    border: 1px solid var(--boken-green);
}
.arrow-icon{
    font-size: 18px;
}
.left-text{
    margin-top: 3px;
    font-size: 14px;
}
.share-bottom-right{
    width: 47%;
    height: 70px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0);
}
body[data-theme="light"] .share-bottom-right{
    background-color: var(--light-gray);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
body[data-theme="dark"] .share-bottom-right{
    background-color: var(--dark-gray);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.share-bottom-right:hover{
    border: 1px solid var(--boken-green);
}
.link-icon{
    font-size: 18px;
}
.right-text{
    margin-top: 3px;
    font-size: 14px;
}











@media (max-width: 400px) {
    .share-profile{
        width: 220px;
        height: 250px;
        margin: auto;
        margin-top: 40px;
    }
    .share-qrcode-container{
        width: 210px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        margin-top: 30px;
    }
    .share-qrcode{
        width: 130px;
        height: 130px;
    }
    .share-at{
        margin-top: 3px;
        font-size: 20px;
        font-family: sans-serif;
        font-family: monospace;
        font-weight: 400;
        color: #07a707;
    }
    
    
    
    
    .share-logo{
        margin-bottom: 7px;
    }
    .share-logo-icon{
        width: 7px;
        height: 7px;
    }
    .share-logo-title{
        font-size: 14px;
        font-weight: 500;
        margin-left: 1px;
    }
    
    
    
    
    
    
    
    
    .share-bottom-row{
        width: 220px;
        margin: auto;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
    }
    .share-bottom-left{
        width: 47%;
        height: 60px;
        border-radius: 8px;
    }
    .arrow-icon{
        font-size: 17px;
    }
    .left-text{
        margin-top: 3px;
        font-size: 11px;
    }
    .share-bottom-right{
        width: 47%;
        height: 60px;
        border-radius: 8px;
    }
    .link-icon{
        font-size: 17px;
    }
    .right-text{
        margin-top: 3px;
        font-size: 11px;
    }
}