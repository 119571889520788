



.pricing-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 650px;
    margin: auto;
    margin-top: 50px;
}
.pricing-each{
    width: 300px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
}
body[data-theme="light"] .pricing-each{
    background-color: var(--light-gray);
    border-radius: 10px;
}
body[data-theme="dark"] .pricing-each{
    background-color: var(--dark-gray);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;
    /* border: 1px solid rgb(228, 228, 228); */
}


.price-title{
    font-size: 26px;
    font-weight: 500;
    width: 80%;
}

.price-text{
    font-size: 14px;
    margin-top: 15px;
    width: 80%;
}
.price-span{
    font-size: 34px;
    font-weight: 600;
}




.pricing-gray-button{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 7px;
    font-size: 15px;
    width: 80%;
    height: 36px;
    margin-top: 20px;
}
body[data-theme="light"] .pricing-gray-button{
    background-color: rgb(230, 230, 230);
    color: black;
}
body[data-theme="dark"] .pricing-gray-button{
    background-color: rgb(63, 63, 63);
    color: white;
}
/* .pricing-gray-button:hover{
    filter: brightness(1.2);
} */
.pricing-green-button{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    background-color: var(--boken-green);
    border-radius: 7px;
    font-size: 15px;
    width: 80%;
    height: 36px;
    margin-top: 20px;
}
.pricing-green-button:hover{
    filter: brightness(1.2);
}
.pricing-white-button{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 7px;
    font-size: 15px;
    width: 80%;
    height: 36px;
    margin-top: 20px;
}
.pricing-white-button:hover{
    filter: brightness(1.2);
}
body[data-theme="light"] .pricing-white-button{
    background-color: rgb(19, 19, 19);
    color: white;
}
body[data-theme="dark"] .pricing-white-button{
    background-color: rgb(230, 230, 230);
    color: black;
}






.feature-list{
    /* height: 10px; */
    margin-top: 10px;
    width: 80%;
}
.feature-list-item{
    margin-top: 10px;
}
.feature-list-icon{
}
body[data-theme="light"] .feature-list-icon{
    color: rgb(0, 142, 0);
}
body[data-theme="dark"] .feature-list-icon{
    color: rgb(0, 159, 0);
}
.feature-list-text{
    margin-left: 5px;
}




.pricing-links-container{
    width: 100%;
    max-width: 370px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}
.pricing-link-each{
    font-size: 13px;
    font-weight: 400;
    color: gray !important;
    text-decoration: none;
}
.pricing-link-each:hover{
    filter: brightness(1.4);
}
.pricing-terms-link{
}
.pricing-privacy-link{
}
.pricing-commerce-link{
}



.pricing-color-option-container{
    width: 100%;
    justify-content: center;
}
.pricing-color-option-container > .color-option-container{
    width: 70px;
    margin-right: 0;
}
.pricing-color-option-container > .color-option-container > .color-option{
    width: 50px;
    height: 50px;
}


.plus-span{
    /* text-decoration: dotted underline; */
}
.plus-color-demo-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 250px;
}
.plus-map-container{
    width: 49%;
    margin-top: 0;
}
.plus-map-container > .amcharts-container{
    width: 100%;
    height: 100%;
}
.plus-ui-container{
    width: 49%;
    transform: scale(1);
}
.plus-ui-container > .country-th-continent{
    width: 120px;
}
.plus-ui-container > .country-td-continent{
    width: 120px;
}



@media (max-width: 900px) {
    .plus-ui-container{
        width: 50%;
        transform: scale(0.9);
    }
}

@media (max-width: 700px) {

    .pricing-container{
        flex-direction: column;
        margin: auto;
    }
    .pricing-each{
        width: 80%;
        /* height: 260px; */
        max-width: 370px;
        min-height: 229px;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        margin-top: 20px;
    }

    .price-title{
        font-size: 24px;
        font-weight: 500;
        width: 80%;
    }
    .price-text{
        font-size: 14px;
        margin-top: 10px;
        width: 80%;
    }
    .price-span{
        font-size: 32px;
        font-weight: 600;
    }




    .pricing-links-container{
        width: 100%;
        max-width: 370px;
        margin: auto;
        display: flex;
        /* flex-direction: column; */
        /* justify-content: space-between; */
        align-items: center;
        margin-top: 15px;
    }
    .pricing-link-each{
        font-size: 12px;
        font-weight: 400;
        color: gray !important;
        text-decoration: none;
        margin-top: 5px;
    }




    .pricing-color-option-container{
        width: 100%;
    }
    .pricing-color-option-container > .color-option-container{
        width: 60px;
    }
    .pricing-color-option-container > .color-option-container > .color-option{
        width: 50px;
        height: 50px;
    }
    .plus-color-demo-container{
        flex-direction: column;
        /* justify-content: space-between; */
        /* align-items: center; */
        /* height: 250px; */
        height: auto;
        margin-top: 20px;
    }
    .plus-map-container{
        width: 90%;
        /* height: 200px; */
        margin-top: 0;
    }
    .plus-map-container > .amcharts-container{
        width: 100%;
        height: 100%;
    }
    .plus-ui-container{
        width: 100%;
        margin-top: 10px;
        transform: scale(1);
    }

}

@media (max-width: 390px) {
    .pricing-each{
        width: 95%;
        max-width: 370px;
        height: auto;
        min-height: 230px;
        padding-top: 20px;
        padding-bottom: 20px;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        margin-top: 20px;
    }

    .price-title{
        font-size: 24px;
        font-weight: 500;
        width: 80%;
    }
    .price-text{
        font-size: 14px;
        margin-top: 10px;
        width: 80%;
    }
    .price-span{
        font-size: 32px;
        font-weight: 600;
    }
    .pricing-gray-button{
        width: 85%;
    }
    .pricing-green-button{
        width: 85%;
    }
    .pricing-white-button{
        width: 85%;
    }



    .pricing-links-container{
        flex-direction: column;
    }
    .pricing-link-each{
    }


    .pricing-color-option-container{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .pricing-color-option-container > .color-option-container{
        width: 50px;
        margin: 0;
    }
    .pricing-color-option-container > .color-option-container > .color-option{
        width: 40px;
        height: 40px;
    }


    .plus-ui-container{
        transform: scale(0.95);
    }

}