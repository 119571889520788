


.billing-success-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
    margin: auto;
    margin-top: 80px;
    padding-bottom: 160px;
}
.billing-success-check-icon{
    font-size: 90px;
    color: var(--boken-green);
}
.billing-success-main-text{
    font-size: 30px;
    font-weight: 500;
    margin: 0;
    margin-top: 30px;
    text-align: center;
}
.billing-success-sub-text{
    text-align: center;
}

.billing-success-button-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
}
.billing-success-go-to-plus{
    width: 200px;
    height: 40px;
    background-color: var(--boken-green);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.billing-success-go-to-plus:hover{
    filter: brightness(1.2);
}
.billing-success-go-to-portal{
    margin-top: 20px;
    width: 200px;
    height: 40px;
    background-color: var(--boken-green);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
body[data-theme="light"] .billing-success-go-to-portal{
    background-color: var(--light-gray);
    color: black;
}
body[data-theme="dark"] .billing-success-go-to-portal{
    background-color: var(--dark-gray);
    color: white;
}