

.dashboard-container{
    display: flex;
    max-width: 1000px;
    min-height: calc(100vh - 291px);
    margin: auto;
}
body[data-theme="light"] .dashboard-container{
    border-top: 0.5px solid #c6c6c6;
}
body[data-theme="dark"] .dashboard-container{
    border-top: 0.5px solid #3b3b3b;
}

.mobile-select {
    display: none; /* Hide by default */
}
.css-1grbnvl-control{
    height: 20px !important;
}

.dashboard-sidebar{
    width: 180px;
    min-width: 180px;
    min-height: 400px;
    /* margin-bottom: 30px; */
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    padding-top: 9px;
    padding-bottom: 9px;
    /* border-radius: 6px; */
}
body[data-theme="light"] .dashboard-sidebar{
    /* background-color: var(--light-gray); */
    border-right: 0.5px solid #c6c6c6;
}
body[data-theme="dark"] .dashboard-sidebar{
    /* background-color: var(--dark-gray); */
    border-right: 0.5px solid #3b3b3b;
}
.sidebar-item{
    /* margin-top: 15px; */
    /* margin-left: 15px; */
    height: 39px;
    margin: 3px 12px 3px 5px;
    padding-left: 12px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    justify-content: left;
    align-items: center;
}
.sidebar-item:hover{}
body[data-theme="light"] .sidebar-item:hover{
    background-color: rgb(236, 236, 236);
}
body[data-theme="dark"] .sidebar-item:hover{
    background-color: rgb(82, 82, 82);
}
.sidebar-icon{
    font-size: 14px;
    margin-right: 9px;
}



.dashboard-right-container{
    flex-grow: 1;
    margin-left: 50px;
    margin-right: 40px;
    margin-bottom: 70px;
    display: flex;
    justify-content: center;
}
.dashboard-right{
    width: 100%;
    max-width: 600px;
    margin: 0;
    margin-top: 10px;
}



/* .settings-each > label{
    margin: 0;
} */
/* .MuiFormControlLabel-root{
    margin: 0;
} */
.settings-each{
    margin-top: 40px;
}
.settings-each > h2{
    margin-bottom: 0;
}
.settings-each > h3{
    margin-bottom: 0;
}
body[data-theme="light"] .settings-text{
    color: rgb(73, 73, 73);
}
body[data-theme="dark"] .settings-text{
    color: rgb(196, 196, 196);
}
.settings-each-content{
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.settings-each-content > p{
    margin-top: 0;
    margin-bottom: 0;
}







.profile-image-content{
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: baseline; */
}
.profile-image{
    width: 65px;
    border-radius: 500px;
    margin-top: 10px;
}
.image-upload-button{
    margin-top: 10px;
    cursor: pointer;
}
.profile-input{
    min-width: 300px;
    width: 85%;
    height: 25px;
    border-radius: 5px;
    padding-left: 5px;
    font-size: 15px;
    /* border: none; */
    /* font-family: monospace; */
}
body[data-theme="light"] .profile-input{
    color: black;
    border: 1px solid gray;
}
body[data-theme="dark"] .profile-input{
    background-color: var(--dark-gray);
    color: white;
    border: 1px solid #525252;
}
.input-desc{
    min-width: 300px;
    width: 85%;
    height: 110px;
    border-radius: 5px;
    padding-left: 5px;
    font-size: 15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    resize: none;
}
body[data-theme="light"] .input-desc{
    color: black;
    border: 1px solid gray;
}
body[data-theme="dark"] .input-desc{
    background-color: var(--dark-gray);
    color: white;
    border: 1px solid;
    border-color: rgb(82, 82, 82);
}
.profile-save-button-container{
    width: 100%;
    /* display: flex; */
    /* justify-content: right; */
}
.profile-save-button{
    margin-top: 45px;
    background-color: var(--boken-green);
    color: white;
    font-size: 16px;
    font-weight: 400;
    border: none;
    border-radius: 5px;
    width: 65px;
    height: 35px;
    cursor: pointer;
}









.settings-each-toggle-label{
    font-size: 15px;
    font-weight: 500;
}
.theme-toggle{
}

.settings-line{
    width: 100%;
    background-color: rgba(255, 0, 0, 0.512);
    height: 1px;
    margin-top: 130px;
    position: relative;
}
.settings-line::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 130px; /* Adjust this to control fade distance */
    /* background: linear-gradient(to bottom, red, transparent); */
    /* background: linear-gradient(to bottom, rgba(255, 0, 0, 0.15), rgba(255, 0, 0, 0.02)); */
    /* background: linear-gradient(to bottom, rgba(255, 0, 0, 0.15), rgba(255, 0, 0, 0.07), rgba(255, 0, 0, 0)); */
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0.17), rgba(255, 0, 0, 0));
    pointer-events: none; /* Ensure it doesn't interfere with other elements */
}
.settings-delete-container{
    /* background-color: rgba(255, 0, 0, 0.136); */
    /* border-radius: 10px; */
    margin-top: 0px;
}
body[data-theme="light"] .delete-account-title{
    color: var(--light-red);
}
body[data-theme="dark"] .delete-account-title{
    color: var(--dark-red);
}
.delete-account-button{
    width: 70px;
    height: 35px;
    background-color: rgb(190, 0, 0);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
}
.is-deleting-input-container{}
.delete-account-input{}

.delete-account-input{
    width: 200px;
    height: 25px;
    border-radius: 5px;
    padding-left: 5px;
    font-size: 15px;
}
body[data-theme="light"] .delete-account-input{
    color: black;
    border: 1px solid gray;
}
body[data-theme="dark"] .delete-account-input{
    background-color: var(--dark-gray);
    color: white;
    border: 1px solid #525252;
}




.is-deleting-button-container{
    height: 50px;
    margin-top: 20px;
    display: flex;
    align-items: center;
}
.delete-really-button{
    width: 120px;
    height: 35px;
    background-color: rgb(190, 0, 0);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}
.delete-cancel-button{
    cursor: pointer;
    margin-left: 20px;
    text-decoration: underline;
    text-decoration-style: dotted;
}






@media (max-width: 600px) {


    .mobile-select > option {}

    .dashboard-sidebar {
        display: none; /* Hide the sidebar on mobile */
    }


    .dashboard-container {
        flex-direction: column; /* Stack elements vertically */
        border-top: none;
    }
    .dashboard-right-container{
        margin-left: 25px;
        margin-right: 27px;
        position: relative;
        display: block;
        /* flex-direction: column; */
    }
    .mobile-select{
        /* display: block; */
        display: flex;
        justify-content: right;
        cursor: pointer;

        /* margin-left: 30px; */
        /* margin-top: 15px; */
        position: absolute;
        top: 33px;
        right: 0;

    }
    .dashboard-right{
        margin: 0;
        margin-top: -20px;
        /* position: relative; */
    }



    .profile-input{
        min-width: 0;
        width: 80%;
    }
    .input-desc{
        min-width: 0;
        width: 80%;
        height: 130px;
    }
}
@media (max-width: 400px) {
    .dashboard-container{
        border-top: none;
    }
    .profile-image-content{
    }
    .profile-input{
        min-width: 0;
        width: 100%;
    }
    .input-desc{
        min-width: 0;
        width: 100%;
        height: 220px;
    }
}